import React from "react";
import { API_STATIC } from '../../utils/utils';

const DailyToolLink = (props) => {
  return (
    <a href={props.link.url} rel="noopener noreferrer" target="_blank" >
      <div className="daily-tool-link">
        <img className="img" src={API_STATIC + props.link.image} alt={props.link.title} />
      </div>
    </a>
  );
}

export default DailyToolLink;