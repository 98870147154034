import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appContext } from '../../context/context.js';


const ButtonAdmin = () => {
  const context = useContext(appContext);
  return (
    <>
    {context.displayAdmin? 
      <Link to="/home" >
        <button onClick={() => context.setDisplayAdmin(!context.displayAdmin)} className={context.isAdmin? "btn-admin": "d-none"} >Retour</button>
      </Link>
      :
      <Link to="/admin/" >
        <button onClick={() => context.setDisplayAdmin(!context.displayAdmin)} className={context.isAdmin? "btn-admin": "d-none"} >Administration</button>
      </Link>
    }
    </>
  )
}

export default ButtonAdmin;