import React, { useContext , useEffect , useState } from 'react';
import * as _ from "lodash";
import Select from 'react-select';

import { API_STATIC } from '../../utils/utils';

import { appContext } from '../../context/context.js';

const Modale = () => {
  const context = useContext(appContext);
  var fileInput = React.createRef()

  const [ title, setTitle] = useState();
  const [ description, setDescription ] = useState();
  const [ url, setUrl] = useState();
  const [ type, setType ] = useState();
  const [ services, setServices] = useState();
  const [ image , setImage] = useState();
  const [ imagePreview , setImagePreview] = useState();
  const [ selectList , setSelectList] = useState([])
  const [ id , setId ] = useState([]);
  const [ error, setError ] = useState();

  const selectStyle = {
    control: base => ({
      ...base,
      borderStyle: "none",
      border: "1px solid #8EAEB6",
      borderRadius: "20px",
      margin: "10px"
    }),
    placeholder: base => ({
      ...base,
      color: "#8EAEB6",
      fontFamily: "Montserrat-Medium, sans-serif",
      fontSize: "16px"
    }),
    singleValue: base => ({
      ...base,
      color: "#8EAEB6",
      fontFamily: "Montserrat-Medium, sans-serif",
      fontSize: "16px"
    })
  }

  useEffect(() => {
    if (context.modaleContent !== {} && context.toolsList && context.isUpdateModale ) {
      var link =  context.modaleContent
      setTitle(link.title)
      setDescription(link.description)
      setUrl(link.url)
      setType(link.type)
      setId(link._id)
      setImagePreview(link.image)
      // create selected services list
      var selectedServices = []
      link.division.map(s => {
        var found = context.servicesList.filter(item => item.name === s || item.value === s )[0]
        if(found) {
          selectedServices.push(found)
        }
        return null;
      })
      setServices(selectedServices)
    } 
    createSelectOption()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.modaleContent , context.toolsList, context.servicesList])

  useEffect(() => {
    createSelectOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!context.isUpdateModale) {
      if (title === undefined) {
        setError("ajoutez un titre")
      } else if (url === undefined) {
        setError("ajoutez un url")
      } else if (type === undefined) {
        setError("ajoutez une catégorie")
      } else if (image === undefined) {
        setError("ajoutez une image")
      } else if (title && url && type && image) {
        setError("ajouter")
      }
    } else {
      if (title === undefined) {
        setError("ajoutez un titre")
      } else if (url === undefined) {
        setError("ajoutez un url")
      } else if (type === undefined) {
        setError("ajoutez une catégorie")
      } else if (imagePreview === undefined) {
        setError("ajoutez une image")
      } else if (title && url && type && imagePreview) {
        setError("ajouter")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title , url , type, image, imagePreview ])

  const readImageFile = (event) => {
    setImage(event.target.files[0])
    var reader = new FileReader();
    reader.onload = function(){
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  } 

  const createTool = () => {
    var formData = new FormData();

    formData.append('title', title);
    formData.append('description', description);
    formData.append('url', url);
    formData.append('type', type);
    formData.append('file', image);
    formData.append('division', services ? setServicesFormData(): JSON.stringify([]));

    context.createToolAction(formData);
  }

  const updateTool = () => {
    var formData = new FormData();

    formData.append('title', title);
    formData.append('description', description);
    formData.append('url', url);
    formData.append('type', type);
    formData.append('file', image);
    formData.append('division', services ? setServicesFormData(): JSON.stringify([]));

    context.updateToolAction(id ,formData);
  }

  const deleteTool = () => {
    context.deleleToolAction(id)
  }

  const setServicesFormData = () => {
    var list = []
    if (services) services.map(s => {
      if (s.name !== undefined) {
        list.push(s.name)
      }
      if (s.value !== undefined) {
        list.push(s.value)
      }
      return s;
    })
    return list;
  }

  const createSelectOption = () => {
    var selectList = []
    if (!context.isUpdateModale) {
      context.toolsList.map(item => {
        return selectList.push(item.categoryName)
      });
      selectList.push("Congrès");
    } else {
      selectList.push(context.modaleContent.type)
      context.toolsList.map(item => {
        if (item.categoryName !== context.modaleContent.type) {
          return selectList.push(item.categoryName)
        }
        return item;
      });
      selectList.push("Congrès");
    }
    setSelectList(_.uniq(selectList).map(item => {
      return ({
        value: item,
        label: item
      })
    }))
  }

  const closeModale = () => {
    context.setModaleContent({})
    context.setModaleIsActive(false)
  }

  return (
    <div className={context.modaleIsActive ? "modale-container " : "d-none"}>
      <div className="modale-content">
        <div className="error-container">
          {error !== "ajouter"?
            <div className="error">
              {error}
            </div>
            :
            null
          }
        </div>
        <button 
          className="btn-close"
          onClick={() => closeModale()}
          >
          <img src={process.env.PUBLIC_URL + '/img/close.svg'} alt={"close"} />
        </button>
        <div className="form-container">
          <div className="col">
            <label htmlFor="file"className="drop-container">
               {imagePreview ?
                <img id="preview" className={"preview-img"} src={API_STATIC + imagePreview} alt={title} />
                :
                <img id="preview" className={"preview-img"} src={process.env.PUBLIC_URL + '/img/image-regular.svg'} alt={title} />
               }
                <p>Uploadez votre image.</p>
                {/* <p>Glissez votre image ici <br/>ou uploadez la.</p> */}
                <div className="btn-blue">Parcourir</div>
                <input
                  type="file"
                  ref={fileInput}
                  className="d-none"
                  id="file"
                  onChange={(e) => readImageFile(e)}
                />
            </label>
          </div>
          <div className="col">
            <Select
              value={selectList.filter(item => {
                if(item.value  === type) return item
                return null;
              })}
              onChange={(e) => setType(e.value)}
              isMulti={false}
              placeholder={"Choisisez une catégorie"}
              styles={selectStyle}
              options={selectList}
            />
            <input
              className="input"
              placeholder="Titre"
              name="label"
              type="text"
              value={title || ''}
              onChange={(e) => setTitle(e.target.value)}
              />
            <Select
              getOptionValue={opt => opt.label}
              value={services}
              onChange={(s) => setServices(s)}
              isMulti={true}
              placeholder={"Choisisez des services"}
              styles={selectStyle}
              options={context.servicesList}
            />
            <textarea
              className="input"
              placeholder="Description..."
              name="legend"
              type="text"
              value={description !== "undefined" ? description :''}
              onChange={(e) => setDescription(e.target.value)}
              />
            <input
              className="input"
              placeholder="Url"
              name="url"
              type="url"
              value={url || ''}
              onChange={(e) => setUrl(e.target.value)}
              />
          </div>
        </div>
        <div className="button-container">
          {context.isUpdateModale
          ? 
            <>
            <button className="btn-validate" onClick={() => updateTool()}>modifier</button>
            <button className="btn-red" onClick={() => deleteTool()} >supprimer le lien</button>
            </>
          : <button
            className={error === "ajouter"? "btn-validate" : "btn-red"}
            disabled={error === "ajouter"? false : true}
            onClick={() => createTool()}>Ajouter</button>
          }
        </div>
      </div>
    </div>
  )
}

export default Modale;