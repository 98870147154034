import React , { useContext } from 'react';
import ToolsList from '../Partials/ToolList';
import { appContext } from '../../context/context';


function ToolListAdmin(props) {
  const context = useContext(appContext);
  return(
    <>
      {props.toolsList.length > 0 ?
        <>
          {props.toolsList.sort((a,b) => { return b.categoryList.length - a.categoryList.length }).map((item, index) => {
            return <ToolsList
                  key={item.categoryName + context.renderNumber}
                  categoryName={item.categoryName}
                  categoryList={item.categoryList}/>
          })}
        </>
      :
        null
      }
    </>
  )
}

export default ToolListAdmin;