import axios from 'axios';

var API_URL = "https://api.octapharma.bakasable.fr/api";

export function post_auth_action( obj) {
  obj.product = "Dashboard"
  return new Promise(function(resolve, reject) {
    postData("/auth/login",resolve,reject , obj);
  })
};

export function reset_password_action( obj) {
  return new Promise(function(resolve, reject) {
    postData("/auth/forgot-password",resolve,reject , obj);
  })
};

export function get_tool_action() {
  return new Promise(function(resolve, reject) {
    getData("/tool",resolve,reject );
  })
};

export function get_services_action() {
  return new Promise(function(resolve, reject) {
    getData("/tool/division",resolve,reject );
  })
};

export function create_tool_action( obj) {
  return new Promise(function(resolve, reject) {
    postData("/tool",resolve,reject , obj);
  })
};

export function update_tool_action(id , obj) {
  return new Promise(function(resolve, reject) {
    putData("/tool/" + id ,resolve,reject , obj);
  })
};

export function update_profile_action(obj) {
  return new Promise(function(resolve, reject) {
    putData("/profile/",resolve,reject , obj);
  })
};

export function delete_tool(id) {
  return new Promise(function(resolve, reject) {
    deleteData("/tool/" + id ,resolve,reject);
  })
};

function getData(url, resolve,reject ) {
  axios.get(API_URL+url , { headers: { Authorization: localStorage.getItem("octaToken")} } )
    .then(function (response) {
      resolve(response);
    })
    .catch((error) => {
      console.log(error)
      reject(error);
  })
}

function putData (url, resolve,reject , body  ) {
  axios.put(API_URL+url , body , { headers: { Authorization: localStorage.getItem("octaToken")} }  )
    .then(function (response) {
      resolve(response);
    })
    .catch((error) => {
      console.log(error) //
      reject(error);
  })
}

function postData (url, resolve,reject , body  ) {
    axios.post(API_URL+url , body , { headers: { Authorization: localStorage.getItem("octaToken")} }  )
      .then(function (response) {
        resolve(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
    })
}

function deleteData(url, resolve,reject) {
  axios.delete(API_URL+url ,  { headers: { Authorization: localStorage.getItem("octaToken")} } )
    .then(function (response) {
      resolve(response);
    })
    .catch((error) => {
      console.log(error)
      // console.error(error.response.data);    // ***

      reject(error);
  })
}
