import React, { useEffect , useState } from "react";

const Clock =() => {
  let date = new Date()
  let hr = checkTime(date.getHours());
  let min = checkTime(date.getMinutes());

  const [timer, setTimer ] = useState({
    hr: hr,
    min: min
  })

  useEffect(() => {
    let clock = setTimeout(function(){ startTime() }, 60000);
    return () => clearTimeout(clock);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer.min]);

  function startTime() {
    var today = new Date();
    hr = checkTime(today.getHours());
    min = checkTime(today.getMinutes());
    setTimer({
      hr: hr,
      min: min
    })
  }

  function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }

  return (
    <h1>{timer.hr} : {timer.min}</h1>
  )
}

export default Clock;