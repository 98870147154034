import React, { useContext } from "react";

import { appContext } from '../../context/context.js';
import { API_STATIC } from '../../utils/utils';

const ToolsList = (props) => {
  const context = useContext(appContext);

  function toogleModaleUpdate(linkToUpdate) {
    context.setModaleIsActive(true)
    context.setIsUpdateModale(true)
    context.setModaleContent(linkToUpdate)
  }

  function renderToolItems() {
    return props.categoryList.map((item, index) => {
      if (!context.displayAdmin) {
        return (
          <a key={index + context.renderNumber} href={item.url} rel="noopener noreferrer" target="_blank">
            <div className="tool-item-container">
              <div className="tool-icon">
                <img src={API_STATIC  + item.image} alt={item.title} />
              </div>
              <div className="tool-text">
                <span className="tool-name">{item.title}</span>
                <span>{item.description !== "undefined" ? item.description : null}</span>
              </div>
            </div>
          </a>
        )
      } else {
        return (
          <div key={index + context.renderNumber} onClick={() => toogleModaleUpdate(item)} className="tool-item-container">
            <div className="tool-icon">
              <img src={API_STATIC  + item.image} alt={item.name} />
            </div>
            <div className="tool-text">
              <span className="tool-name">{item.title}</span>
              <span>{item.description !== "undefined" ? item.description : null}</span>
            </div>
          </div>
        )
      }
    })
  }

  return (
    <div className="tools-list">
      <h4 >{props.categoryName}</h4>
      <div className="title-bar"></div>
      {renderToolItems()}
    </div>
  )
}

export default ToolsList;