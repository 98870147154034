import React,{ useState, useEffect, useContext } from "react";
import * as _ from "lodash";

import { appContext } from '../../context/context.js';

import DailyToolsList from "../Partials/DailyToolsList";
import ToolsList from "../Partials/ToolList";
import Clock from "../Partials/Clock.jsx";
import Search from "../Partials/Search";
import Menu from "../Partials/Menu";
import ButtonAdmin from "../Partials/ButtonAdmin.jsx";
import ModaleProfile from '../Partials/ModaleProfile';

function Home(props) {
  const context = useContext(appContext);
  let [ selectedCategory, setSelectedCategory] = useState(null);
  let [ iconMenu, setIconMenu] = useState("menu");
  let [ classAnimMenu, setClassAnimMenu ] = useState("d-none");
  let [ bgRandom ] = useState(_.random(1, 21));

  useEffect(() => {
    if (selectedCategory !== null) {
      displayMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  function renderDailyToolsList() {
    if (context.toolsList.length > 0) {
      var dailyToolsList  = context.toolsList.filter(obj => { return obj.categoryName === "Outils Octapharma au quotidien" })[0].categoryList;
      return <DailyToolsList categoryList={dailyToolsList}/>
    }
  };

  function displayMenu() {
    if (classAnimMenu === "menu-list anim-open") {
      setIconMenu("menu");
      setClassAnimMenu("menu-list anim-close");
    } else {
      setIconMenu("arrow-close");
      setClassAnimMenu("menu-list anim-open");
    }
  }

  window.addEventListener("resize", adjustOnResize);

  function adjustOnResize() {
    if (window.innerWidth < 576 ) {
      setSelectedCategory(null);
      setClassAnimMenu("d-none");
    }
  };

  function logOut() {
    props.history.push('/');
    context.logOut();
  };

  function renderToolsList() {
    if (context.toolsList.length > 0) {
        return context.toolsList.filter(obj => { return  obj.categoryName !== "Outils Octapharma au quotidien" }).map((item, index) => {
        return <ToolsList
                key={index}
                categoryName={item.categoryName}
                categoryList={item.categoryList}/>
      });
    }
  };

  function renderToolsListMobile(categoryObj) {
    return <ToolsList
            categoryName={categoryObj.categoryName}
            categoryList={categoryObj.categoryList}/>
  };

  return (
    <div className="main-container">
      <div className="bg-img-container">
        <img className="logo-octapharma" src={process.env.PUBLIC_URL + '/img/logo-octapharma-white.svg'} alt={"logo-octapharma"} />
        <div className="btn-nav-md">
          <ButtonAdmin />
          <button className="btn-admin" onClick={() => context.setModaleProfileIsActive(true)}>Profil</button>
          <button className="btn-admin" onClick={() => logOut()}>Déconnexion</button>
        </div>
        <div className={"bg-" + bgRandom.toString() }>
        </div>
      </div>
      <header>
        <Clock />
        <Search />
      </header>
        {selectedCategory !== null ?
          <>{renderToolsListMobile(selectedCategory)}</>
          :
          <>{renderDailyToolsList()}</>
        }
      <div className="tools-list-main-container">
        {renderToolsList()}
      </div>
      {context.modaleProfileIsActive ? <ModaleProfile /> : null }
      <Menu
        history={props.history}
        displayMenu={displayMenu}
        classAnimMenu={classAnimMenu}
        setSelectedCategory={setSelectedCategory}
        iconMenu={iconMenu}
        />
    </div>
  );
};

export default Home;