import React, { useState , createContext , useEffect } from 'react';
import history from '../history';
import { post_auth_action , update_profile_action,reset_password_action , get_tool_action , create_tool_action, delete_tool ,  update_tool_action, get_services_action } from '../actions/actions'
import * as _ from "lodash";
import jwt_decode from 'jwt-decode';

export const appContext =  createContext();

export default function AppContextProvider(props) {
  const [ toolsList , setToolsList ] = useState([]);
  const [ servicesList , setServicesList ] = useState([])
  const [ displayAdmin , setDisplayAdmin ] = useState(false);
  const [ isAdmin , setIsAdmin ]= useState(false);
  const [ modaleIsActive , setModaleIsActive ] = useState(false);
  const [ modaleProfileIsActive ,setModaleProfileIsActive ] = useState(false)
  const [ modaleContent , setModaleContent] = useState({})
  const [ isUpdateModale , setIsUpdateModale ] = useState(false)
  const [ user , setUser ] = useState({})
  const [ isAuthenticated , setIsAuthenticated ] = useState(false);
  const [ renderNumber , setRenderNumber ] = useState(0)
  const [ resetMessage , setResetMessage ] = useState();
  const [ error , setError ] = useState()
 
  useEffect(() => {
    if( user.role === "Admin") {
      setIsAdmin(true);
    }
  }, [user])

  useEffect(() => {
    if(localStorage.getItem('octaToken')) {
      setIsAuthenticated(true);
      const decode = jwt_decode(localStorage.getItem('octaToken'));
      setUser(decode);
      setIsAuthenticated(true);
    }
  },[]);

  useEffect(() => {
    if(isAuthenticated) {
      history.push('/home');
      getTools();
      getServices();
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated ])

  function createToolsList(list) {
    var toolsList = []
    var allCategories  = [];

    list.map( item => { return allCategories.push(item.type) });
    _.uniq(allCategories).map( item => {
      var obj = {
        categoryName: item,
        categoryList: list.filter(obj => obj.type === item) 
      }
      return toolsList.push(obj)
    })
    setToolsList(toolsList);
    setRenderNumber(new Date().getTime());
  }

  function getTools() {
    get_tool_action()
    .then(
      res => {
        createToolsList(res.data.tools)
      }
    ).catch(
      err => {
        if (err.response.status === 401) {
          console.log("logout");
          logOut();
        }
      }
    );
  };

  function getServices() {
    get_services_action()
      .then(
        res => {
          setServicesList(res.data.divisions)
        }
      ).catch(
        err => {
          if (err.response.status === 401) {
            console.log("logout");
            logOut();
          }
        }
      );
  }

  function logOut() {
    setUser({});
    setIsAdmin(false);
    setIsAuthenticated(false);
    localStorage.removeItem('octaToken');
    localStorage.removeItem('octaUser');
  };

	const state = {
    servicesList,
    error,
    setError,
    renderNumber,
    user: user,
    isUpdateModale,
    setIsUpdateModale,
    modaleProfileIsActive,
    setModaleProfileIsActive: (val) => setModaleProfileIsActive(val),
    setUser : (val) => setUser(val),
    isAuthenticated: isAuthenticated,
    setIsAuthenticated : (bool) => setIsAuthenticated(bool),

    deleleToolAction: (id) => delete_tool(id)
      .then(
        res => {
          get_tool_action()
            .then(
              res => {
                createToolsList(res.data.tools)
                setModaleIsActive(false)
                setModaleContent({})
              }
            ).catch(
              err => {
                if (err.response.status === 401) {
                  console.log("logout");
                  logOut();
                }
              }
            );
          }
      ),

    getServicesAction: () => getServices(),
    
    updateProfileAction : ( body ) => update_profile_action(body)
    .then(
      res => {
        setUser(res.data.user);
        setModaleProfileIsActive(false);
        localStorage.setItem('octaUser', JSON.stringify(res.data.user))
      }
    ).catch(
      err => {
        if (err.response) {
          setError("Votre mot de passe est invalide");
          if (err.response.status === 401) {
            logOut();
          }
        }
      }
    ),

    resetMessage: resetMessage ,
    setResetMessage: (val) => setResetMessage(val),
    resetPasswordAction : ( body ) => reset_password_action(body)
    .then(
      res => {
        setResetMessage("Veuillez vérifier votre e-mail pour connaître le lien permettant de réinitialiser votre mot de passe.")
      }
    ).catch(
      err => {
        if (err.response) {
          setError("Votre email est invalide");
          if (err.response.status === 401) {
            logOut();
          }
        }
      }
    ),
  
  
    postAuthAction : ( body ) => post_auth_action(body)
      .then(
        res => {
          if (res.data.user) {
            setUser(res.data.user)
            setIsAuthenticated(true)
            localStorage.setItem('octaToken', res.data.token )
            localStorage.setItem('octaUser', JSON.stringify(res.data.user))
          }
        }
      ).catch(
        error => {
          if (error && error.response && error.response.status === 401) {
            setError("Login ou mot de passe invalide");
          }
        }
      ),

    getToolAction: () => getTools(),

    createToolAction: (body) => create_tool_action(body)
      .then(
        res => {
          get_tool_action()
            .then(
              res => {
                createToolsList(res.data.tools)
                setModaleIsActive(false)
                setModaleContent({})
              }
            )
          }
      ).catch(
        error => {
          if (error && error.response && error.response.status === 401) {
            console.log("logout");
            logOut();
          }
        }
      ),


    updateToolAction: (id, body) =>  update_tool_action(id, body)
      .then(
        res => {
          get_tool_action()
            .then(
              res => {
                createToolsList(res.data.tools)
                setModaleIsActive(false)
                setModaleContent({})
              }
            )
          }
      ).catch(
        error => {
          if (error && error.response && error.response.status === 401) {
            console.log("logout");
            logOut();
          }
        }
      ),

    logOut: () => logOut(),

    toolsList: toolsList,
    setToolsList: (val) => setToolsList(val),
    displayAdmin: displayAdmin,
    setDisplayAdmin: (val) => setDisplayAdmin(val),
    isAdmin: isAdmin,
    setIsAdmin: (val) => setIsAdmin(val),
    modaleIsActive: modaleIsActive,
    setModaleIsActive: (val) => setModaleIsActive(val),
    modaleContent: modaleContent,
    setModaleContent: (val) => setModaleContent(val),
   };
    return <appContext.Provider value={state}>{props.children}</appContext.Provider>
}

export const AppContextConsumer = appContext.Consumer;