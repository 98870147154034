import React, { useContext , useEffect, useState } from 'react';

import { appContext } from '../../context/context.js';

const Modale = () => {
  const context = useContext(appContext);

  const [ firstName, setFirstName ] = useState(context.user.firstName);
  const [ lastName, setLastName] = useState(context.user.lastName);
  const [ mail , setMail ] = useState();
  const [ displayMailInput, setDisplayMailInput] = useState(false);
  const [ error , setError ] = useState();

  useEffect(() => {
    setFirstName(context.user.firstName);
    setLastName(context.user.lastName);
  }, [context.user])

  useEffect(() => {
    if (mail === undefined || "" ){
      setError("vous devez entrer un e-mail");
    } if (context.error) {
      setError(context.error);
    }
    else {
      setError();
      context.setResetMessage(); 
    }
    // eslint-disable-next-line
  }, [context.error , mail])

  const updateProfile = () => {
    let obj = {
      firstName: firstName,
      lastName: lastName,
    };

    if (displayMailInput) {
      context.resetPasswordAction({email: mail})
    } else {
      context.updateProfileAction(obj);
    }
  }

  const keepPassword = () => {
    setDisplayMailInput(!displayMailInput);
    setMail()
  }

  const closeModale = () => {
    context.setModaleProfileIsActive(false)
  }

  return (
    <div className={context.modaleProfileIsActive ? "modale-container " : "d-none"}>
      <div className="modale-content profile">
        <div className="error-container">
          {error !== "ajouter"?
            <div className="error profile">
              {error}
            </div>
            :
            null
          }
        </div>
        <button 
          className="btn-close"
          onClick={() => closeModale()}
          >
          <img src={process.env.PUBLIC_URL + '/img/close.svg'} alt={"close"} />
        </button>
        <div className="form-container">
          <div className="col profile">
           
            {displayMailInput ?
              <>
              <h4>Modifier votre mot de passe</h4>
                <input
                  className="input"
                  placeholder="e-mail"
                  name="email"
                  type="email"
                  value={mail || ""}
                  onChange={(e) => setMail(e.target.value)}
                  />
                  {context.resetMessage && <p>{context.resetMessage}</p>}
                </>
              :
              <>
                <h4>Modifier votre profil</h4>
                <input
                  className="input"
                  placeholder="Nom"
                  name="FirstName"
                  type="text"
                  value={firstName || ""}
                  onChange={(e) => setFirstName(e.target.value)}
                  />
                <input
                  className="input"
                  placeholder="Prénom"
                  name="LastName"
                  type="text"
                  value={lastName || ""}
                  onChange={(e) => setLastName(e.target.value)}
                  />
              </>
            }
            {!displayMailInput ?
              <button
                className={"btn-validate password"}
                onClick={() => setDisplayMailInput(!displayMailInput)}>Changer votre mot de passe</button>
              :
              <button
                className={"btn-validate password"}
                onClick={() => keepPassword()}>Conserver votre mot de passe</button>
            }
          </div>
        </div>
        <div className="button-container">
           <button
            className={"btn-validate"}
            onClick={() => updateProfile()}>Valider</button>
        </div>
      </div>
    </div>
  )
}

export default Modale;