import React, { useContext } from "react";
import ButtonAdmin from "../Partials/ButtonAdmin";
import { Link } from "react-router-dom";

import { appContext } from '../../context/context.js';

function Menu (props) {
  const context = useContext(appContext);

  function RenderMenuList() {
    return context.toolsList.filter(obj => { return  obj.categoryName !== "Outils Octapharma au quotidien" }).map((item, index )=> {
      return <li key={index } onClick={() => displayCategory(item)}>{item.categoryName}</li>
    })
  }

  function displayCategory(category) {
    context.setModaleIsActive(false)
    props.setSelectedCategory(category)
  }

  function displayDailyTools() {
    props.setSelectedCategory(null)
    context.setModaleIsActive(false)
    props.displayMenu()
  }

  function logOut() {
    props.history.push('/')
    context.logOut()
  };

  function displayModaleProfile() {
    props.displayMenu();
    context.setModaleProfileIsActive(true)
  }

  return (
      <>
      <div className={props.classAnimMenu}>
        <img className="logo-octapharma" src={process.env.PUBLIC_URL + '/img/logo-octapharma.png'} alt={"logo-octapharma"} />
        <ul>
        <Link to="/home" >
          <li onClick={() => displayDailyTools() }> Octopharma au quotidien</li>
          {RenderMenuList()}
        </Link>
          <li><ButtonAdmin /></li>
          <li><button className="btn-admin" onClick={() => displayModaleProfile(true)}>Profile</button></li>
          <li><button className="btn-admin" onClick={() => logOut()}>Déconnexion</button></li>
        </ul>
      </div>
      <div className="menu-container">
        <div className="btn-menu" onClick={() => props.displayMenu()}>
          <img className="icon-menu" src={process.env.PUBLIC_URL + '/img/'+ props.iconMenu +'.svg'} alt="icon-menu" />
        </div>
      </div>
      </>
  )
}

export default Menu;