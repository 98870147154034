import React from "react";
import { Router, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import './style/scss/App.scss';

import history from './history';
import Home from './components/Pages/Home';
import Admin from './components/Pages/Admin';
import Login from './components/Pages/Login';
import AppContextProvider from './context/context';

function App() {

  return (
    <Router history={history} >
      <AppContextProvider>
        <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
        >
          <Route path="/" exact component={Login} />
          <Route path="/home"  component={Home} />
          <Route  path="/admin/" component={Admin} />
        </AnimatedSwitch>
      </AppContextProvider>
    </Router>
  );
}

export default App;
