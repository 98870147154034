import React, { useState }from "react";

const Search =() => {
  const [ inputSearch, setInputSearch ] = useState("")

  function onChangeInput(e) {
    setInputSearch(e.target.value)
  }

  function onKeyPressSearch(e) {
    if(e.key === "Enter") {
      searchOnQwant()
    }
 } 

  function searchOnQwant() {
    window.open(`https://www.qwant.com/?q=${inputSearch}&l=fr&btnG=`, '_blank');
  }

  return (
    <div className="search-container">
      <div className="search-bar">
          <input className="search-input" aria-label="search" type="text" name="qwant-search" id="qwant-search" maxLength="255" onChange={(e) => onChangeInput(e)} onKeyPress={ (e) => onKeyPressSearch(e)}/>
          <input type="hidden" name="l" value="fr"/>
          <button type="submit" name="btnG">
            <img className="search-icon" src={process.env.PUBLIC_URL + '/img/search.svg'} alt="search-icon" onClick={() => searchOnQwant()}/>
          </button>
        </div>
    </div>
  )
}

export default Search;