import React from "react";
import DailyToolLink from "./DailyToolLink";

const DailyToolsList = (props) => {
  function renderDailyToolsList() {
    return props.categoryList.map((item, index) => {
      return <DailyToolLink
                key={index}
                link={item}/>
    })
  }
  return (
      <div className="daily-tools-container">
        <div className="daily-tools-list">
          {renderDailyToolsList()}
        </div>
      </div>
  )
}

export default DailyToolsList;