import React,{ useState , useEffect , useContext } from "react";
import * as _ from "lodash";

import { appContext } from '../../context/context';

const Login = () => {
  const context = useContext(appContext);
  var [ bgRandom ] = useState(_.random(1, 21));
  var [ email , setEmail ] = useState("");
  var [ password , setPassword ] = useState("");

  useEffect(() => {
    if (validateEmail(email) === false && email !== "") {
      context.setError("votre email est invalide")
    } else {
      context.setError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])
  
  const submitLogin = () => {
    context.setResetMessage();
    if (validateEmail(email) === false && email !== "") {
      context.setError("votre email est invalide")
    } else if (email !== "" && password === "") {
      context.setError("compléter votre mot de passe")
    } else {
      context.setError()
      context.postAuthAction({ 
        email: email,
        password: password
      })
    }
  };

  const resetPassword = () => {
    if (validateEmail(email) === true ) {
      context.resetPasswordAction({email: email})
    } else {
      context.setError("votre email est invalide")
    }

  }

  const validateEmail = (email) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div className="main-container">
      <div className="bg-img-container">
        <div className={"bg-" + bgRandom.toString() + " bg-admin"}>
        </div>
      </div>
      <div className="login-container">
        <img className="logo-octapharma" src={process.env.PUBLIC_URL + '/img/logo-octapharma-blue.svg'} alt={"logo-octapharma"} />
        <div className="logo-ligne"></div>
        <div className="form-login">
          <input
            className="input-login"
            placeholder="Adresse email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
          <input
            className="input-login"
            placeholder="mot de passe"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
          <button
            className="btn-validate"
            onClick={() => submitLogin()} 
            >
            connexion
          </button>
          <button
            type="button"
            className="btn-reset"
            onClick={() => resetPassword()}
            >
            Mot de passe oublié
          </button>
          <div className="text-error">{context.error}</div>
          <p className="text-success">{context.resetMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default Login;