import React,{ useState, useEffect, useContext } from "react";
import * as _ from "lodash";

import { appContext, AppContextConsumer } from '../../context/context';

import Menu from "../Partials/Menu";
import ButtonAdmin from "../Partials/ButtonAdmin.jsx";
import Modale from "../Partials/Modale";
import ToolsListAdmin from "../Partials/ToolListAdmin";

const Admin = (props) => {
  const context = useContext(appContext);
  const [ selectedCategory, setSelectedCategory] = useState(null);
  const [ iconMenu, setIconMenu] = useState("menu");
  const [ classAnimMenu, setClassAnimMenu ] = useState("d-none");
  const [ bgRandom ] = useState(_.random(1, 21));

  useEffect(() => {
    if (selectedCategory !== null) {
      displayMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  useEffect(() => {
    if(context.user && context.user.role !== "Admin") {
      props.history.push("/home")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.user])

  function displayMenu() {
    if (classAnimMenu === "menu-list anim-open") {
      setIconMenu("menu")
      setClassAnimMenu("menu-list anim-close")
    } else {
      setIconMenu("close")
      setClassAnimMenu("menu-list anim-open")
    }
  }

  window.addEventListener("resize", adjustOnResize)

  function adjustOnResize() {
    if (window.innerWidth < 576 ) {
      setSelectedCategory(null)
      setClassAnimMenu("d-none")
    }
  }

  function toogleModaleCreate() {
    context.setIsUpdateModale(false)
    context.setModaleIsActive(true)
  }

  return (
    <>
      <div className={`main-container admin-container ${context.modaleIsActive ? "blured-container" : ""} ` }>
        <div className="bg-img-container">
          <div className="logo-container">
          <img className="logo-octapharma" src={process.env.PUBLIC_URL + '/img/logo-octapharma-blue.svg'} alt={"logo-octapharma"} />
          </div>
          <div className="btn-admin-container">
              <ButtonAdmin />
              <div>
                <button onClick={() => toogleModaleCreate(true)} className="btn-validate btn-new-link">Nouveau lien</button>
              </div>
          </div>
          <div className={"bg-" + bgRandom.toString() + " bg-admin"}>
          </div>
        </div>
        <div className="tools-list-admin-container">
          <AppContextConsumer>
            {({ toolsList }) => (
              <ToolsListAdmin toolsList={toolsList} />
            )}
          </AppContextConsumer>
        </div>
        <Menu
          displayMenu={displayMenu}
          classAnimMenu={classAnimMenu}
          setSelectedCategory={setSelectedCategory}
          iconMenu={iconMenu}
          />
      </div>
      {context.modaleIsActive ? <Modale /> : null }
    </>
  )
}

export default Admin;